import { Controller } from "@hotwired/stimulus"
import SignaturePad from "signature_pad"

export default class extends Controller<HTMLDivElement> {
  declare signaturePad: SignaturePad
  declare signatureInput: HTMLInputElement
  static targets = ["confirmButton", "clearButton"]
  declare confirmButtonTarget: HTMLButtonElement
  declare clearButtonTarget: HTMLButtonElement

  connect() {
    const canvas = document.createElement("canvas")
    canvas.width = 340
    canvas.height = 200

    this.signatureInput = document.createElement("input")
    this.signatureInput.name = this.element.dataset.name
    this.signatureInput.type = "hidden"
    this.signatureInput.required = true
    this.element.appendChild(this.signatureInput)

    this.signaturePad = new SignaturePad(canvas)
    this.signaturePad.addEventListener("endStroke", this.handleSignatureStroke.bind(this))

    this.handleSignatureStroke()
    this.element.appendChild(canvas)

    this.element.dataset.formvalidatorTarget = "field"

    this.validate()
  }

  disconnect() {
    delete this.signaturePad
    delete this.signatureInput
  }

  handleSignatureStroke() {
    let data = this.signaturePad.toDataURL("image/png")
    this.signatureInput.value = data
    this.validate()
  }

  validate() {
    if (this.signaturePad.isEmpty()) {
      this.element.dataset.invalid = "true"
    } else {
      this.element.dataset.invalid = "false"
    }
  }

  clear() {
    this.signaturePad.clear()
    this.signaturePad.on()
    this.confirmButtonTarget.hidden = false
  }

  confirm(e: Event) {
    this.validate()
    if (this.element.dataset.invalid == "true") { return }
    this.signaturePad.off()
    // this.clearButtonTarget.hidden = true
    this.confirmButtonTarget.hidden = true
  }
}
